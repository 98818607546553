// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "gatsby";

import Layout from "../components/layout";
import PageInfo from "../components/pageinfo";
import logoWrox from "../images/wrox3.png";
import { BuyLink } from "../components/buy-link";

const ExtLink = () =>
    <FaExternalLinkAlt style={{ paddingLeft: "2px", fontSize: "smaller"/*, opacity: 0.8*/ }} />;

const amazon = ({ name }) => `https://${name}/dp/1119367956`;

const places = [
    {
        code: "us", name: "United States", local: null, sites: [
            { name: "bookshop.org", link: "https://bookshop.org/books/javascript-the-new-toys/9781119367956" },
            { name: "amazon.com", link: amazon },
            { name: "Barnes & Noble", link: "https://www.barnesandnoble.com/w/javascript-t-j-crowder/1124581239" },
        ]
    },
    {
        code: "au", name: "Australia", local: null, sites: [
            { name: "amazon.com.au", link: amazon },
        ]
    },
    {
        code: "br", name: "Brazil", local: "Brasil", sites: [
            { name: "amazon.com.br", link: amazon },
        ]
    },
    {
        code: "ca", name: "Canada", local: null, sites: [
            { name: "amazon.ca", link: amazon },
        ]
    },
    // {code: "cn", name: "China", local: "中国大陆", site: "amazon.cn"},
    {
        code: "fr", name: "France", local: null, sites: [
            { name: "amazon.fr", link: amazon },
        ]
    },
    {
        code: "de", name: "Germany", local: "Deutschland", sites: [
            { name: "amazon.de", link: amazon },
        ]
    },
    {
        code: "it", name: "Italy", local: "Italia", sites: [
            { name: "amazon.it", link: amazon },
        ]
    },
    {
        code: "jp", name: "Japan", local: "日本", sites: [
            { name: "amazon.co.jp", link: amazon },
        ]
    },
    {
        code: "mx", name: "Mexico", local: "México", sites: [
            { name: "amazon.com.mx", link: amazon },
        ]
    },
    {
        code: "nl", name: "Netherlands", local: "Nederland", sites: [
            { name: "amazon.nl", link: amazon },
        ]
    },
    {
        code: "pl", name: "Poland", local: "Polska", sites: [
            { name: "amazon.pl", link: amazon },
        ]
    },
    // {code: "sa", name: "Saudi Arabia", local: "المملكة العربية السعودية", site: "amazon.sa"},
    {
        code: "sg", name: "Singapore", local: null, sites: [
            { name: "amazon.sg", link: amazon },
        ]
    },
    {
        code: "es", name: "Spain", local: "España", sites: [
            { name: "amazon.es", link: amazon },
        ]
    },
    {
        code: "se", name: "Sweden", local: "Sverige", sites: [
            { name: "amazon.se", link: amazon },
        ]
    },
    {
        code: "tr", name: "Turkey", local: "Türkiye", sites: [
            { name: "amazon.com.tr", link: amazon },
        ]
    },
    {
        code: "ae", name: "United Arab Emirates", local: null, sites: [
            { name: "amazon.ae", link: amazon },
        ]
    },
    {
        code: "gb", name: "United Kingdom", local: null, sites: [
            { name: "uk.bookshop.org", link: "https://uk.bookshop.org/books/javascript-the-new-toys/9781119367956" },
            { name: "amazon.co.uk", link: amazon },
        ]
    },
];

const WhereToBuySection = () => (
    <section className="where-to-buy">
        <h1>Where to Buy</h1>
        <hr />
        <div className="where-to-buy-links-request">
            Please @ me <Link className="no-hover" target="_blank" style={{borderBottom: "1px solid"}} to="https://twitter.com/tjcrowder">on Twitter</Link> if there are other links
            I should add. I'd like to have more non-Amazon options in various countries.
            Thanks in advance!
        </div>
        <hr />
        <div key="publisher" className="where-to-buy-links">
            <div>
                <img style={{ verticalAlign: "middle", marginRight: 8, width: 117, height: 45 }} src={logoWrox} />
                <span>Direct From Publisher</span>
            </div>
            <div>
                <BuyLink to="https://www.wiley.com/JavaScript%3A+The+New+Toys-p-9781119367963" className="inline">wiley.com</BuyLink>
            </div>
        </div>
        {places.map(({ code, name, local, sites }) => {
            const display = local ? `${local} (${name})` : name;
            const flag = `/flags/${code}.svg`;
            return <div key={code} className="where-to-buy-links">
                <hr />
                <div>
                    <img src={flag} />
                    <span>{display}</span>
                </div>
                <div className="where-to-buy-buttons">
                    {sites.map(site => {
                        let { name, link } = site;
                        if (typeof link === "function") {
                            link = link(site);
                        }
                        return <BuyLink key={name} to={link} className="inline">{name}</BuyLink>;
                    })}
                </div>
            </div>;
        })}
    </section>
);

const WhereToBuyPage = () => (
    <Layout>
        <PageInfo title="Where to Buy" />
        <WhereToBuySection />
    </Layout>
);

export default WhereToBuyPage;
